<template>
  <v-alert
    border="left"
    color="warning"
    type="warning"
    class="connect_lexoffice"
    colored-border
    elevation="3"
  >
    Sie haben ihr Konto noch nicht mit lexoffice verbunden. Registrieren Sie
    <a
      target="_blank"
      @click="openUrl('https://app.lexoffice.de/settings/#/public-api')"
      >hier</a
    >
    einen Api-Schlüssel und geben ihn hier ein:<br />

    <v-text-field
      outlined
      v-model="lexoffice_key"
      :error-messages="keyErrors"
      label="Lexoffice Api-Schlüssel"
      required
    ></v-text-field>
    <v-btn
      class="mr-4"
      target="_blank"
      @click="openUrl('https://app.lexoffice.de/settings/#/public-api')"
    >
      Key generieren
    </v-btn>
    <v-btn
      :loading="saving || !user"
      :disabled="!user"
      class="mr-4"
      @click="connect"
      color="success"
    >
      lexoffice verbinden
    </v-btn>
  </v-alert>
</template>

<style scoped lang="scss">
.connect_lexoffice {
  ::v-deep .v-input {
    margin-top: 1rem;
  }
}
</style>

<script>
export default {
  data() {
    return {
      saving: false,
      lexoffice_key: "",
      keyErrors: []
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    }
  },
  methods: {
    connect() {
      this.saving = true;
      try {
        this.$store.dispatch("profile/update", {
          type: "users",
          id: this.user.id,
          lexoffice_key: this.lexoffice_key
        });
        this.$store.dispatch("profile/me");
        this.$store.dispatch("alerts/success", "Einstellungen gespeichert.");
        this.saving = false;
      } catch (e) {
        this.$store.dispatch("alerts/error", "Fehler!");
        this.saving = false;
      }
    }
  }
};
</script>
